//Filename: js/src/main.js
var ui = {
    init: function() {
        var that = this;
        that.calcMainHeight();
        $(window).on('resize', function() {
            that.calcMainHeight()
        })
        
        $('body').animate({
            opacity: 1
        })

        $('.navbar-toggler').on('click', function() {
            $('.navbar-collapse').addClass('show');
        })

        $('.navbar-close').on('click', function() {
            $('.navbar-collapse').removeClass('show');
        })
    },
    calcMainHeight: function() {
        var calcMain = $('header').outerHeight() + $('footer').outerHeight();
        $('main').css({
            "min-height": 'calc(100vh - ' + calcMain + 'px)'
        })
    },
    swiper: function() {
        var swiper = new Swiper('.swiper-container', {
            pagination: {
                el: '.swiper-pagination',
                type: 'progressbar',
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            effect: 'fade',
            speed: 900,
            fadeEffect: {
                crossFade: true
            },
            // Disable preloading of all images
            preloadImages: true,
            // Enable lazy loading
            lazy: true,
            on: {
                slideChangeTransitionStart: function() {
                    if(swiper.activeIndex > 0) {
                        $('.mainslider__content-background').addClass('mainslider__content-background--hide');
                    } else {
                        $('.mainslider__content-background').removeClass('mainslider__content-background--hide');
                    }
                }
            }
        });
    }
}

$(function() {
    ui.init();
    ui.swiper();
})